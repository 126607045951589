import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "button"
    }}>{`Button`}</h1>
    <p>{`Button styles for actions in forms, dialogs, and more with support for multiple sizes, states, and more.`}</p>
    <p>
  To see all the colors and their variations go to the{" "}
  <a className="pink" href="/styleguide">
    Styleguide page
  </a>
    </p>
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <p>{`To change color just use 'button-' + color class, like `}<inlineCode parentName="p">{`button-squid`}</inlineCode>{` or `}<inlineCode parentName="p">{`button-aqua`}</inlineCode></p>
    <button className="button button-squid">Button</button>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button class="button button-squid">
  Button
</button>
`}</code></pre>
    <h2 {...{
      "id": "rounded"
    }}>{`Rounded`}</h2>
    <p>{`Style with border rounded, just add the class `}<inlineCode parentName="p">{`.rounded`}</inlineCode></p>
    <button className="button button-squid rounded">Button</button>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button class="button button-squid rounded">
  Button
</button>
`}</code></pre>
    <h2 {...{
      "id": "inverted"
    }}>{`Inverted`}</h2>
    <p>{`Just add `}<inlineCode parentName="p">{`inverted`}</inlineCode>{` class`}</p>
    <button className="button inverted button-squid">Button</button>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button class="button inverted button-squid">
  Button
</button>
`}</code></pre>
    <h2 {...{
      "id": "disabled"
    }}>{`Disabled`}</h2>
    <p>{`Just add `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` class`}</p>
    <button className="button button-squid disabled">Button</button>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button class="button button-squid disabled">
  Button
</button>
`}</code></pre>
    <h2 {...{
      "id": "sizes"
    }}>{`Sizes`}</h2>
    <p>{`Buttons has sizes class`}</p>
    <p>{`To use the medium size button, just don't add any size class`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.button-sm
.button-lg
.button-xl
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      